import React from "react"
import AuthContent from "../gatsby-theme-wild-child/components/auth/AuthContent"

import NameSpace from "../gatsby-theme-wild-child/components/namespaces/nameSpace"

function NameSpaces() {
  return (
    <AuthContent>
      <NameSpace />
    </AuthContent>
  )
}

export default NameSpaces

export const Head = () => (
  <>
    <title>
      TaxGPT by Virtuzone | World's First AI-Powered UAE Corporate Tax Assistant
    </title>
    <meta
      name="facebook-domain-verification"
      content="0qis6k25boblo98elnosvjz5ws7gtm"
    />
    <meta name="google-site-verification" content="fdkrhMVPDQOPHf3A4czsCkEuBbJ_lkvh8yj01E6fn4Q" />
  </>
)
