import React, { memo, useEffect, useState } from "react"
import {
  Badge,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react"
import Section from "gatsby-theme-wild-child/src/components/Section"
import { EditIcon, DeleteIcon } from "@chakra-ui/icons"

import axios from "axios"
import DeleteItemModal from "../common/DeleteItem"

const options = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
]

function NmaeSpace(props) {
  const [openNamespaceModal, setOpenNamespaceModal] = useState(false)

  const [unTouched, setUnTouched] = useState(true)

  const [errors, setErrors] = useState({})

  const [namespaceListing, setNamespaceListing] = useState([])
  const [loading, setLoading] = useState(false)
  const [namespaceLoading, setNamespaceLoading] = useState(false)

  const [updateNamespace, setUpdateNamespace] = useState(false)
  const [selectedNamespace, setSelectedNamespace] = useState("")
  const [updateIndex, setUpdateIndex] = useState(null)
  const [deleteItem, setDeleteItem] = useState(null)
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [formData, setFormData] = useState({
    title: "",
    code: "",
    status: "",
  })

  const handleOpenNamespaceModal = () => {
    setOpenNamespaceModal(true)
  }

  const handleChange = event => {
    const fieldName = event.target.name
    const fieldValue = event.target.value

    setFormData(prevFormData => ({
      ...prevFormData,
      [fieldName]: fieldValue,
    }))
    setUnTouched(false)
  }

  const handleBlur = event => {
    const fieldName = event.target.name
    const fieldValue = event.target.value
    const prevErrors = { ...errors }

    if (!fieldValue) {
      prevErrors[fieldName] = "This field is required"
    } else {
      prevErrors[fieldName] = ""
    }

    let newErrors = {}
    for (const key in prevErrors) {
      if (prevErrors[key] !== "") {
        newErrors[key] = prevErrors[key]
      }
    }

    setErrors(newErrors)
  }

  const handleCloseNamespaceModal = () => {
    setFormData({
      title: "",
      code: "",
      status: "",
    })
    setUpdateIndex(null)
    setOpenNamespaceModal(false)
    setUpdateNamespace(false)
  }
  const handleListingNamespace = () => {
    setNamespaceLoading(true)
    axios
      .get(
        `${process.env.GATSBY_API_BASE_URL}/namespaces/list`,
        {}
      )
      .then((response: any) => {
        setNamespaceListing(response?.data.response ?? [])
        setNamespaceLoading(false)
      })
      .catch(error => {
        console.error(error, "nameSpace error")
        setNamespaceLoading(false)
      })
  }
  useEffect(() => {
    handleListingNamespace()
  }, [])

  const handleUpdateNmaespace = (namespace, index) => {
    setUpdateNamespace(true)
    setFormData({
      title: namespace?.title,
      code: namespace?.code,
      status: namespace?.status,
    })
    setSelectedNamespace(namespace)
    setUpdateIndex(index)
    setOpenNamespaceModal(true)
  }

  const handleDeleteNamespace = (namespace, index) => {
    setDeleteItem(namespace)
    setDeleteIndex(index)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
    setDeleteItem(null)
    setDeleteIndex(null)
  }

  const handleSubmit = () => {
    for (const key in formData) {
      if (!formData[key].length) {
        handleBlur({
          target: {
            name: key,
            value: formData[key],
          },
        })

        return
      }
    }

    setLoading(true)
    if (updateNamespace) {
      axios
        .patch(
          `${process.env.GATSBY_API_BASE_URL}/namespaces/${selectedNamespace?._id}/update`,
          {
            code: formData?.code,
            title: formData?.title,
            status: formData?.status,
          }
        )
        .then(response => {
          const updatedItems = [...namespaceListing]
          updatedItems[updateIndex] = response.data.response
          setNamespaceListing(updatedItems)
          setLoading(false)
          handleCloseNamespaceModal()
        })
        .catch(error => {
          console.error(error, "nameSpace error")
          setLoading(false)
        })
    } else {
      axios
        .post(
          `${process.env.GATSBY_API_BASE_URL}/namespaces/store`,
          {
            code: formData?.code,
            title: formData?.title,
            status: formData?.status,
          }
        )
        .then((response: any) => {
          setNamespaceListing([...namespaceListing, response?.data?.response])
          setLoading(false)
          handleCloseNamespaceModal()
        })
        .catch(error => {
          console.error(error, "nameSpace error")
          setLoading(false)
        })
    }
  }

  const handleDeleteSubmit = () => {
    setDeleteLoading(true)
    axios
      .delete(
        `${process.env.GATSBY_API_BASE_URL}/namespaces/${deleteItem?._id}/delete`,
        {}
      )
      .then(response => {
        const updatedItems = [...namespaceListing]
        updatedItems.splice(deleteIndex, 1)
        setNamespaceListing(updatedItems)
        setDeleteLoading(false)
        handleCloseDeleteModal()
      })
      .catch(error => {
        console.error(error, "nameSpace error")
        setDeleteLoading(false)
      })
  }

  return (
    <Section mt={8} minH="100vh">
      <SimpleGrid columns={2} spacing={10} mb={10}>
        <Box display={"flex"} alignItems={"center"}>
          <Heading fontSize="2xl" color="red.full" p={0}>
            Namespaces
          </Heading>
        </Box>
        <Box display={"flex"} justifyContent={"end"}>
          <Button bg="red.full" zIndex={0} onClick={handleOpenNamespaceModal}>
            Create Namespace
          </Button>
        </Box>
      </SimpleGrid>
      {namespaceLoading ? (
        <VStack w="full" spacing={12} pb={10} h="full" justify="center">
          <Spinner color="red.500" />
        </VStack>
      ) : (
        <VStack w="full" spacing={12} pb={10} h="full" justify="center">
          <TableContainer w="full">
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>title</Th>
                  <Th>code</Th>
                  <Th>status</Th>
                  <Th textAlign={"center"}>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {namespaceListing?.length > 0 ? (
                  namespaceListing.map((namespace: any, index) => (
                    <Tr key={index}>
                      <Td>{namespace.title}</Td>
                      <Td>{namespace.code}</Td>

                      <Td>
                        <Badge
                          variant="subtle"
                          colorScheme={
                            namespace.status === "Active" ? "green" : "yellow"
                          }
                        >
                          {namespace.status}
                        </Badge>
                      </Td>
                      <Td textAlign={"center"}>
                        <EditIcon
                          cursor={"pointer"}
                          mr={5}
                          onClick={() =>
                            handleUpdateNmaespace(namespace, index)
                          }
                        />
                        <DeleteIcon
                          cursor={"pointer"}
                          onClick={() =>
                            handleDeleteNamespace(namespace, index)
                          }
                        />
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Text mt={5}>Record not available</Text>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      )}
      <Modal
        isOpen={openNamespaceModal}
        onClose={handleCloseNamespaceModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="600px">
          <ModalHeader>
            {updateNamespace ? "Update namespace" : "Add namespace"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit} noValidate>
              <FormControl
                id="title"
                isRequired
                mt={4}
                isInvalid={errors["title"]}
              >
                <Input
                  name="title"
                  placeholder="Title"
                  type="text"
                  size={"md"}
                  borderWidth="1px"
                  bg={formData.title ? "white" : "gray.50"}
                  borderColor="gray.50"
                  borderRadius="md"
                  value={formData.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors["title"]}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="code"
                isRequired
                mt={4}
                isInvalid={errors["code"]}
              >
                <Input
                  name="code"
                  placeholder="Code"
                  type="text"
                  size={"md"}
                  borderWidth="1px"
                  bg={formData.code ? "white" : "gray.50"}
                  borderColor="gray.50"
                  borderRadius="md"
                  value={formData.code}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors["code"]}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="status"
                isRequired
                mt={4}
                isInvalid={errors["status"]}
              >
                <Select
                  iconColor="gray.200"
                  name="status"
                  borderWidth="1px"
                  borderColor="gray.50"
                  borderRadius="md"
                  size={"md"}
                  bg={formData.status ? "white" : "gray.50"}
                  value={formData.status}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="">Select status</option>
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors["status"]}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="gray.100"
              color="dark.full"
              mr={3}
              onClick={async () => {
                handleCloseNamespaceModal()
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              bg={"red.full"}
              type="submit"
              textAlign="center"
              zIndex="0"
              disabled={loading || Object.keys(errors).length > 0}
            >
              {loading
                ? "Please wait..."
                : updateNamespace
                ? "Update"
                : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {openDeleteModal && (
        <DeleteItemModal
          open={openDeleteModal}
          cancel={handleCloseDeleteModal}
          submit={handleDeleteSubmit}
          loading={deleteLoading}
          title={deleteItem?.title}
        />
      )}
    </Section>
  )
}

export default memo(NmaeSpace)
