import React, { useEffect, ReactNode } from "react"
import { navigate } from "gatsby"
import Section from "gatsby-theme-wild-child/src/components/Section"
import { Center, Spinner } from "@chakra-ui/react"
import { useRealmApp } from "../../context/RealmApp"

export interface Props {
  children: ReactNode
  redirect?: string
  loadingElement?: any
}

export default function AuthContent(props: Props) {
  const { currentUser, loading }: any = useRealmApp()
  const { children, redirect = "/login" } = props

  // Navigate unauthenticated users to Log In page.
  useEffect(() => {
    if (
      !currentUser?._profile?.data?.email &&
      !window.location.href.includes(redirect)
    ) {
      navigate(redirect)
    }
  }, [currentUser])

  return currentUser && !loading ? (
    <>{children}</>
  ) : (
    <Section h="100vh" bg="dark.full" id="loadingAuth">
      <Center layerStyle="fillSpace">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="blue.900"
          color="orange.full"
          size="xl"
        />
      </Center>
    </Section>
  )
}
