import React from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"

function DeleteItemModal({ open, cancel, submit, loading, title }) {
  return (
    <Modal isOpen={open} onClose={cancel} isCentered>
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize={"20px"} fontWeight={"semibold"}>
            Are you sure you want to delete {title}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="gray.100"
            color="dark.full"
            mr={3}
            onClick={async () => {
              cancel()
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={submit}
            bg={"red.full"}
            type="submit"
            textAlign="center"
            zIndex="0"
          >
            {loading ? "Please wait..." : "Delete"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default DeleteItemModal
